import React from 'react'
import { Row, Col, Image, Typography } from 'antd'
import { Link } from 'react-router-dom';
const { Title } = Typography;

const importAll = (r) => {
    let images = {}
    // eslint-disable-next-line
    r.keys().map((item) => { images[item.replace('./', '')] = r(item) })
    return images
}
const customers = Object.values(importAll(require.context('../lib/customers', false, /\.(png|jpe?g|svg)$/)))
const customers_logos = customers.map((customer, i) =>
    <Col span={6} key={i}>
        <Link to={'/home/' + customer.split('.')[0].split('/').pop()}>
            <Image src={customer} preview={false} />
        </Link>
        {/* <a href={window.location.origin + '/home/' + customer.split('.')[0].split('/').pop()}>
            <Image src={customer} preview={false} />
        </a> */}
    </Col>
)

const CustomerSelector = () => {
    return (
        <>
            <Row>
                <Col span={24}>
                    <Title>Choose a customer</Title>
                </Col>
            </Row>
            <Row gutter={[8, 8]}>
                {customers_logos}
            </Row>
        </>
    )
}

export default CustomerSelector