import styles from "../style.module.less"
import { Card, Col, Row, Spin } from "antd"
import { useEffect, useState } from "react"
import Recorder from "./Recorder.jsx"

const RecorderForm = (props) => {
    const [isRecorded, setIsRecorded] = useState(false)
    const [recordingFile, setRecordingFile] = useState({})
    const [loading, setLoading] = useState(false);


    useEffect(() => {
        if (isRecorded) {
            props.onSubmit(recordingFile, setLoading, setIsRecorded)
        }
        // eslint-disable-next-line
    }, [isRecorded])


    return (
        <>
            <p className={styles.top_para}> Click the microphone to record, and click again to stop recording: </p>
            <Row>
                <Col span={8}>
                    <Recorder setRecordingFile={setRecordingFile} setIsRecorded={setIsRecorded} />
                </Col>
                <Col span={16} >
                    <Card className={styles.record_text_card}>
                        <Spin spinning={loading} size="large">
                            <p>{props.random_text}</p>
                        </Spin>
                    </Card>
                </Col>
            </Row>
            <Row className={styles.center_sons} style={{ marginTop: '20px' }}>
                <Col span={24}>
                </Col>
            </Row>
        </>
    )
}

export default RecorderForm