import { Button } from "antd"
import { Link, useParams } from "react-router-dom"

const Footer = () => {
    const customer = useParams().customer
    return (
        <Link to={'/home/' + customer}>
            <Button type='primary'>
                Home
            </Button>
        </Link>
    )
}

export default Footer