import styles from "../../style.module.less"
import { Form, Row, Col, Input, Button, notification } from "antd"
import { MailOutlined } from '@ant-design/icons';
import axios from "axios";

const AuthForm = (props) => {
    const stepOneFinish = () => {
        const server_url = process.env.REACT_APP_SERVER_URL + '/auth/first_step'
        const data = {
            "username": props?.form.getFieldValue('email')
        }
        axios.post(server_url, data)
            .then(res => {
                if (res.data.is_ok) {
                    props.next()
                } else {
                    // message.error(res.data.message)
                    notification.error({
                        message: 'An error occurred',
                        description: res.data.message,
                        placement: 'top',
                        // style: { marginTop: '15%' }
                    })
                }
            })
    }

    return (
        <>
            <p className={styles.top_para}> Fill in your details: </p>
            <Form form={props.form} name='auth' onFinish={stepOneFinish} size="large">
                <Row align="middle">
                    <Col span={14} offset={5}>
                        <Form.Item
                            name="email"
                            rules={[{ required: true, message: 'Email or Username is required' }]}
                        >
                            <Input
                                prefix={<MailOutlined />}
                                placeholder="Username or Email"
                            />
                        </Form.Item>
                    </Col>
                </Row>
                <Row className={styles.center_sons}>
                    <Col span={24}>
                        <Form.Item>
                            <Button type="primary" htmlType="submit" size="large">
                                Next
                            </Button>
                        </Form.Item>
                    </Col>
                </Row>
            </Form>
        </>
    )
}

export default AuthForm