import { CheckCircleTwoTone } from '@ant-design/icons';

import { Result } from 'antd'

const Success = (props) => {
    let title = ""
    if (props.action === "enroll") {
        title = "The user was successfully enrolled."
    }
    else { // For props.action == "auth"
        title = "The user was successfully verified."
    }
    return (
        <Result
            status="success"
            icon={<CheckCircleTwoTone twoToneColor="#52c41a" />}
            title={title}
            subTitle="Click the Home button to return to the main screen."
        />
    )
}

export default Success