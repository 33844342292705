import styles from "../style.module.less"
import { Row, Col, Image } from 'antd'
import { Link, useLocation } from 'react-router-dom'

const Header = () => {
    const location = useLocation() // Causes re-render on location change, which is necessary so the customer var gets the right value
    const customer = location.pathname.split('/').pop()
    return (
        <>
            {customer &&
                <Row>
                    <Col span={12}>
                        <Link to={'/home/' + customer}>
                            <Image src={require('../lib/multikol_logo.webp')} preview={false} />
                        </Link>
                    </Col>
                    <Col span={12} className={styles.right_sons}>
                        <Image src={require(`../lib/customers/${customer}.png`)} preview={false} className={styles.customer_logo_img} />
                    </Col>
                </Row>
            }
        </>
    )
}

export default Header