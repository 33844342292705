import './App.less'
import styles from "./style.module.less"
import { BrowserRouter, Route, Routes } from 'react-router-dom'
import { Card } from 'antd'
import Home from './Components/Home.jsx'
import Header from './Components/Header.jsx'
import Enroll from './Components/Enroll/Enroll.jsx'
import Auth from './Components/Auth/Auth'
import Identify from './Components/Identify/Identify'
import CustomerSelector from './Components/CustomerSelector.jsx'

function App() {
  return (
    <BrowserRouter>
      <Card className={styles.main_card}>
        <Header />
        <Routes>
          <Route exact path={'/'} element={<CustomerSelector />}></Route>
          <Route exact path={'/home/:customer'} element={<Home />}></Route>
          <Route exact path={'/enroll/:customer'} element={<Enroll />}></Route>
          <Route exact path={'/auth/:customer'} element={<Auth />}></Route>
          <Route exact path={'/identify/:customer'} element={<Identify />}></Route>
        </Routes>
      </Card >
    </BrowserRouter>
  );
}

export default App;
