// import { Form } from "antd";
import { useEffect, useState } from "react";
import RecorderForm from "../RecorderForm";
// import AuthForm from "../Auth/AuthForm.jsx"
import Footer from '../Footer.jsx'
import axios from "axios";

const Identify = () => {
    const [authText, setAuthText] = useState('')
    useEffect(() => {
        axios.get(process.env.REACT_APP_SERVER_URL + "/auth/get_auth_sentence")
            .then(res => {
                setAuthText(res.data.challenge_text)
            })
    }, [])

    return (
        <>
            <RecorderForm random_text={authText} />
            <Footer />
        </>
    )
}

export default Identify