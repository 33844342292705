import { Form, notification } from "antd";
import { useState } from "react";
import EnrollForm from "./EnrollForm"
import RecorderForm from "../RecorderForm";
import axios from "axios";
import Footer from '../Footer.jsx'
import Success from "../Success";

const Enroll = () => {
    const [enrollForm] = Form.useForm()
    const [currentStep, setCurrentStep] = useState(0)
    const [enrollText, setEnrollText] = useState('')
    const next = () => {
        setCurrentStep(currentStep + 1)
        axios.get(process.env.REACT_APP_SERVER_URL + "/enroll/get_enroll_sentence")
            .then(res => {
                setEnrollText(res.data.challenge_text)
            })
    }

    const onSubmit = (recordingFile, setLoading, setIsRecorded) => {
        const server_url = process.env.REACT_APP_SERVER_URL + '/enroll/second_step'
        const data = {
            "username": enrollForm.getFieldValue('email'),
            "name": enrollForm.getFieldValue('name'),
            "requested_text": enrollText,
            recording_file: recordingFile
        }

        setLoading(true)
        axios.post(server_url, data, { headers: { 'Content-Type': 'multipart/form-data' } })
            .then(res => {
                if (res.data.is_ok) {
                    // message.success(res.data.message)
                    setCurrentStep(currentStep + 1)
                } else {
                    notification.error({
                        message: res.data.message,
                        description: 'We heard you say: ' + res.data.recorded_text,
                        placement: 'top',
                        // style: { marginTop: '15%' }
                    })
                    // message.error('We heard you say: ' + res.data.recorded_text)
                    //     .then(() => message.error(res.data.message))
                    setIsRecorded(false) // Init for a new try, if this one will not be successful
                }
                setLoading(false)
            })
    }

    const steps = [
        <EnrollForm form={enrollForm} next={next} />,
        <RecorderForm form={enrollForm} random_text={enrollText} onSubmit={onSubmit} />,
        <Success action="enroll" />
    ];
    return (
        <>
            {steps[currentStep]}
            <Footer />
        </>
    )
}

export default Enroll