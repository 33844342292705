import { useParams } from "react-router-dom";
import styles from "../style.module.less"
import { Link } from 'react-router-dom'
import { Typography, Button, Space, Row, Col } from "antd"
const { Text } = Typography;

const Home = () => {
    const customer = useParams().customer
    return (
        <>
            <p className={styles.top_para}> Welcome to the <Text className={styles.text_primary} strong> MultiKol</Text> voice authenticator.</p>
            <Row className={styles.center_sons}>
                <Col span={24}>
                    <Space direction='vertical' size='large' className={styles.space_home}>
                        <Button type="primary" size='large' block><Link to={'/enroll/' + customer}>Enroll</Link></Button>
                        <Button type="primary" size='large' block><Link to={'/auth/' + customer}>Authenticate</Link></Button>
                        {/* <Button type="primary" size='large' block><Link to={'/identify/' + customer}>Identify</Link></Button> */}
                    </Space>
                </Col>
            </Row>
        </>
    )
}

export default Home