import { useState, useRef, useEffect } from "react";
import Lottie from "lottie-react";
import recording_lottie from "../lib/recording_lottie.json";
import AudioReactRecorder, { RecordState } from 'audio-react-recorder'
import { notification } from "antd"


// TODO use a different audio recorder package. I think this one could be good:
// https://www.npmjs.com/package/react-audio-voice-recorder
// As it has a lot of weekly downloads, it is maintained (as of June 2023), and I tried to plug it in and it seems really nice. 
// It has a "hook only" 

const Recorder = (props) => {
    const lottieRef = useRef(null)
    const [recordState, setRecordState] = useState(RecordState.STOP)
    useEffect(() => {
        lottieRef.current.pause()
    }, []);

    const onStop = (audioData) => {
        // TODO For some reason, sometimes when clicking on the recording button, it starts and then immediately stops.
        // This if clause is here to prevent that from causing any effect
        const audio_size = audioData.blob.size
        if (audio_size > 10000) {
            const audioFile = new File([audioData.blob], 'recording.wav', {
                type: audioData.type,
                lastModified: Date.now()
            });
            props.setIsRecorded(true)
            props.setRecordingFile(audioFile)
        } else {
            if (audio_size > 200) {
                notification.error({
                    message: 'An error occurred',
                    description: "There was a problem with the recording. Please try again.",
                    placement: 'top',
                })
            }
        }
    }

    const onClick = () => {
        if (recordState === RecordState.STOP) {
            lottieRef.current.play()
            setRecordState(RecordState.START)
        } else {
            lottieRef.current.stop()
            setRecordState(RecordState.STOP)
        }
    }

    return (
        <>
            <div style={{ display: 'none' }}>
                <AudioReactRecorder state={recordState} onStop={onStop} />
            </div>
            <Lottie lottieRef={lottieRef} animationData={recording_lottie} autoplay={false} loop={true} onClick={onClick} />
            {/* {(recordState !== RecordState.STOP) && <p>Click to stop recording</p>} */}
        </>
    )
}

export default Recorder